<template>
    <main id="liste">
        <HeaderTab 
            :title="$t('invoice.mes_factures_fournisseurs')" 
            :swiper_tabs="swiperTabs" 
            :swiper_action="change_tab"    
        />

        <div v-if="!hasVerifiedEmail">
            <router-view></router-view>
        </div>

        <div v-else id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable
                                v-show="actual_tab == 'a_payer'"
                                id_table="addressed_invoices_to_pay"
                                :items="items_to_pay"
                                :busy.sync="working"
                                primaryKey="duedate_id"
                            />

                              <CustomTable
                                v-show="actual_tab == 'soldees'"
                                id_table="addressed_invoices_sold"
                                :items="items_sold"
                                :busy.sync="working"
                                primaryKey="duedate_id"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Invoice from '@/mixins/Invoice.js'
    import Navigation from '@/mixins/Navigation.js'

	export default {
        name: "InvoiceAddressed",
        mixins:[Invoice, Navigation],
		data () {
			return {
                actual_tab: 'a_payer',
                swiper_tabs: [{
                        id: 'a_payer',
						label: 'invoice.titre_a_payer',
						active: true
					}, {
                        id: 'soldees',
                        label: 'invoice.titre_soldees',
                        active: false
					}
                ],
                
                items_to_pay: [],
                items_sold: [],

                working: false,

                events_tab: {
                    "TableAction::goToPdfInvoice": (params) => {
                        this.getAddressedPdfInvoice(params.invoice_ids, params.invoice_nums)
                    }
                }
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                if(!this.hasVerifiedEmail) {
                    return this.$router.push({ name: 'CheckEmailIA' })
                }
                this.working = true

                const promises = [
                    this.load_to_pay(),
                    this.load_paid()
                ]

                Promise.all(promises)
                .catch(e => {
                    this.failureToast("toast.info_lecture_failed")
                    console.error("InvoiceAddressed::init_component => Error:", e)
                })
                .finally(() => {
                    this.working = false
                })
            },

            async load_to_pay() {
                return new Promise((res, rej) => {
                    this.getAddressedInvoicesToPay()
                    .then(r => {
                        this.items_to_pay = r
                        res()
                    })
                    .catch(e => {
                        rej(e)
                    })
                })
            },

            async load_paid() {
                return new Promise((res, rej) => {
                    this.getAddressedInvoicesSold()
                    .then(r => {
                        this.items_sold = r
                        res()
                    })
                    .catch(e => {
                        rej(e)
                    })
                }) 
            },

            change_tab(tab) {
                // On dévalide les autres tabs
                this.swiper_tabs.forEach(tab => {
                    tab.active = false
                })

                // On active la target
                tab.active = true

                // On change de tableau
                this.actual_tab = tab.id
            }
        },
        
        computed: {
            hasVerifiedEmail() {
                return this.$store.state.userAccess.hasEmailVerified
            },

            swiperTabs() {
                if(!this.hasVerifiedEmail) return
                return this.swiper_tabs
            }
        },

		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}
</script>
